import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import DocumentMeta from 'react-document-meta';
import { HeartTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { BlogMenu } from 'components/blog/menu';
import BlogSimilar from 'components/blog/similar';
import './style.scss';

import { selectBlogDetail, selectBlogSimilar } from 'ducks/blog/selectors';
import { getBlogDetail, getBlogSimilar, likeBlog } from 'ducks/blog/actions';

import { DATE_FORMAT } from 'constants/date';

import { Link } from 'components/ui/link';
import YandexShare from 'components/ui/yandexShare';
import { LayoutLending } from 'components/ui/layout-lendiing';
import { ROUTES } from 'constants/routes';
import { getMeta } from 'constants/document';

type MatchParams = {
    id: string;
};

const BlogDetail = React.memo(() => {
    const history = useHistory();
    const put = useDispatch();
    const blog = useSelector(selectBlogDetail);
    const similar = useSelector(selectBlogSimilar);
    const { params: { id } } = useRouteMatch<MatchParams>();

    const meta = getMeta({
        title: blog?.title,
        description: blog?.meta_description,
        keywords: blog?.meta_keywords,
        img: blog?.previewImage,
        type: 'article',
    });

    if (!id) {
        history.replace('/');
    }

    useEffect(() => {
        if (id) {
            put(getBlogDetail({ id }));
            put(getBlogSimilar({ id }));
        }
    }, []);

    const onLike = useCallback((ID: number | string) => {
        if (ID) {
            put(likeBlog(ID));
        }
    }, []);

    return (
        <DocumentMeta { ...meta }>
            <LayoutLending>
                <div className="container">
                    <div className="blog blog--detail">
                        <BlogMenu />
                        {
                            // eslint-disable-next-line no-nested-ternary
                            blog && Object.keys(blog).length > 0 ? (
                                <div className="blog__detail ck-content">
                                    <h2 className="blog__detail-title">{ blog.title }</h2>
                                    <div className="blog__detail-info">Категория: { blog.blogCategories.map((cat: any) => <Link to={ `/blog?categoryId=${cat.id}` } key={ cat.id }> { cat.name }</Link>) } | Опубликовано: { moment(blog.createDate).format(DATE_FORMAT.DATE_TIME) }</div>
                                    { /* eslint-disable-next-line react/no-danger */ }
                                    <div className="blog__detail-description" dangerouslySetInnerHTML={ { __html: blog.description } } />
                                    <div className="blog__detail-footer">
                                        <div className="blog__detail_like">
                                            <HeartTwoTone twoToneColor={ blog.likesAdd ? 'red' : '#000' } onClick={ () => onLike(blog.id) } /> { blog.likes !== 0 && (<div>{ blog.likes }</div>) }
                                        </div>
                                        <div className="blog__detail_share">
                                            <YandexShare id={ blog.id } url={ window.location.href } title={ blog.title } description={ blog.previewText } />
                                        </div>
                                    </div>
                                </div>
                            ) : blog ? <Redirect to={ ROUTES.BLOG.path } /> : null
                        }
                    </div>
                    { similar && <BlogSimilar list={ similar } /> }
                </div>
            </LayoutLending>
        </DocumentMeta>
    );
});

export default BlogDetail;
