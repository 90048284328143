import React, { useEffect, useState, useCallback } from 'react';

import { Pagination } from 'antd';
import { useLocation } from 'react-router';
import DocumentMeta from 'react-document-meta';
import { BlogItem } from 'components/blog/item';
import { BlogMenu } from 'components/blog/menu';

import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';

import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';

import {
    selectBlog, selectBlogPagination, selectBlogFilter,
} from 'ducks/blog/selectors';
import {
    getBlog, removeBlog, likeBlog,
} from 'ducks/blog/actions';
import { BlogTags } from 'components/blog/Tags';
import { BlogEdit } from 'components/blog/edit';
import { LayoutLending } from 'components/ui/layout-lendiing';
import { getMeta } from 'constants/document';
import { fetchers } from 'api';
import { BlogFloatMenu } from 'components/blog/floatMenu';

const Blog = React.memo(({ size }: any) => {
    const [blogEditVisible, setBlogEditVisible] = React.useState<any>(false);
    const [data, setData] = React.useState<any>({});
    const put = useDispatch();
    const blog = useSelector(selectBlog);
    const pagination = useSelector(selectBlogPagination);
    const filter = useSelector(selectBlogFilter);
    const user = useSelector(selectProfile);
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const [selectedTags, setSelectedTags] = useState<any>([]);

    const meta = getMeta({
        type: 'website',
    });

    const handleChangeTags = (tag: number, checked: any) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t: any) => t !== tag);

        setSelectedTags(nextSelectedTags);
        const categoryIdStr = query.get('categoryId');
        const page = query.get('page') ? Number(query.get('page')) : 1;

        if (categoryIdStr) {
            const categoryId = parseInt(categoryIdStr, 10);

            put(getBlog({ pagination: { page }, filter: { categoryId, tags: nextSelectedTags } }));
        } else {
            put(getBlog({ pagination: { page }, filter: { tags: nextSelectedTags } }));
        }
    };

    const blogEdit = useCallback(async (id: any = {}) => {
        const res = await fetchers.getBlogDetail({ id });

        setData(res);
        setBlogEditVisible(true);
    }, [setData, setBlogEditVisible]);

    const blogEditToggle = (v: any = false) => {
        setBlogEditVisible(v);
    };

    const onChangePagination = useCallback((page: number) => {
        put(getBlog({ pagination: { page }, filter }));
    }, [filter]);

    useEffect(() => {
        const categoryIdStr = query.get('categoryId');
        const page = query.get('page') ? Number(query.get('page')) : 1;

        if (categoryIdStr) {
            const categoryId = parseInt(categoryIdStr, 10);

            put(getBlog({ pagination: { page }, filter: { categoryId } }));
        } else {
            put(getBlog({ pagination: { page }, filter: {} }));
        }
    }, []);

    const onDelete = useCallback((id: any) => {
        put(removeBlog(id));
    }, []);

    const onLike = useCallback((id: number) => {
        put(likeBlog(id));
    }, []);

    return (
        <DocumentMeta { ...meta }>
            <LayoutLending>
                <BlogFloatMenu />
                <div className="blog-top container">
                    <BlogMenu />
                    <BlogTags selectedTags={ selectedTags } onSelectTags={ handleChangeTags } />
                    <div className="blog-body">
                        <StackGrid
                            component="div"
                            duration={ 2000 }
                            // eslint-disable-next-line no-nested-ternary
                            columnWidth={ size.width >= 320 && size.width < 480 ? '100%' : size.width >= 480 && size.width < 768 ? '50%' : size.width >= 768 && size.width <= 991 ? '33.3%' : '25%' }
                            gutterWidth={ 20 }
                            gutterHeight={ 20 }
                            monitorImagesLoaded={ true }
                            className="blog-outer"
                        >
                            { blog && blog.map((item) => (
                                <BlogItem
                                    onLike={ onLike }
                                    onDelete={ onDelete }
                                    onEdit={ blogEdit }
                                    user={ user }
                                    { ...item }
                                    key={ item.id }
                                />
                            )) }
                        </StackGrid>
                        <div className="blog__list-pagination">
                            <Pagination
                                total={ pagination.total }
                                pageSize={ pagination.pageSize }
                                current={ pagination.page }
                                onChange={ onChangePagination }
                            />
                        </div>
                    </div>
                </div>
                { blogEditVisible && (<BlogEdit data={ data } visible={ blogEditVisible } onFormToggle={ blogEditToggle } onCancel={ () => blogEditToggle(false) } />) }
            </LayoutLending>
        </DocumentMeta>
    );
});

export default sizeMe()(Blog);
